export const getshowCreateEditForum = (state) => {
	return state.showCreateEditForum
}
export const getForum = (state) => {
	return state.forum.data;
}
export const getForumListMeta = (state) => {
	return state.forum.meta;
}
export const getForumListLinks = (state) => {
	return state.forum.links;
}
export const getForumCurrentPage = (state) => {
	return state.forum.current_page;
}
export const getForumPublishedCount = (state) => {
	return state.forum.forumPublishedCount;
}
export const getForumMembersEngagedCount = (state) => {
	return state.forum.forumMemberEngagedCount;
}
export const getForumSession = (state) => {
	return state.forum.isSession;
}
export const getForumMessages = (state) => {
	return state.forumMessages.data;
}
export const getForumMessagesListMeta = (state) => {
	return state.forumMessages.meta;
}
export const getForumMessagesListLinks = (state) => {
	return state.forumMessages.links;
}
export const getForumMessagesCurrentPage = (state) => {
	return state.forumMessages.current_page;
}