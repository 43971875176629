import { createI18n } from "vue-i18n";
import { isEmpty } from "lodash";

// Load locale messages based on the current locale stored in localStorage
function loadLocaleMessages() {
  let currentLocale = localStorage.getItem("localforage/smartartclient/locale");
  if (isEmpty(currentLocale)) {
    currentLocale = "en"; // Fallback to English if no locale is found
  }

  try {
    return require(`./locales/${currentLocale.replace(/['"]+/g, "")}.json`);
  } catch (error) {
    // Fallback to English if the selected locale is not supported
    localStorage.setItem("localforage/smartartclient/locale", "en");
    alert("Sorry for the inconvenience. Language not supported yet.");
    return require(`./locales/en.json`).default;
  }
}

// Get the current locale from localStorage or default to 'en'
function getCurrentLocale() {
  let currentLocale = "en";
  if (!isEmpty(localStorage.getItem("localforage/smartartclient/locale"))) {
    currentLocale = localStorage
      .getItem("localforage/smartartclient/locale")
      .replace(/['"]+/g, "");
  }
  return currentLocale;
}

// Create the i18n instance with the current locale and loaded messages
export default createI18n({
  locale: getCurrentLocale() || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
