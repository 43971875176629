export const showCreateEditForum = (state, flag) => {
	state.showCreateEditForum = flag
}
export const setForum = (state, forum) => {
	state.forum.data = forum.forumList.data;
	state.forum.current_page = forum.forumList.current_page;
	state.forum.links = forum.forumList.links
	state.forum.meta = {
		current_page: forum.forumList.current_page,
		from: forum.forumList.from,
		to:  forum.forumList.to,
		total: forum.forumList.total,
		perPage: forum.forumList.per_page
	};
	state.forum.forumMemberEngagedCount = forum.forumMemberEngagedCount;
	state.forum.forumPublishedCount = forum.forumPublishedCount;
	state.forum.isSession = forum.isSession;
}
export const setForumMessages = (state, forum) => {
	state.forumMessages.data = forum.forumMessagesList.data;
	state.forumMessages.current_page = forum.forumMessagesList.current_page;
	state.forumMessages.links = forum.forumMessagesList.links
	state.forumMessages.meta = {
		current_page: forum.forumMessagesList.current_page,
		from: forum.forumMessagesList.from,
		to:  forum.forumMessagesList.to,
		total: forum.forumMessagesList.total,
		perPage: forum.forumMessagesList.per_page
	};
}