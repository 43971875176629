export default [
	{
        path: '/forum',
        component: () => import('../components/Forum.vue'),
        name: 'forum-list',
        meta: {                  
        }
    },
    {
        path: '/forum-list',
        component: () => import('../components/ForumList.vue'),
        name: 'forum',
        meta: {                  
        }
    },
]