export default {
	showCreateEditForum: false,
	forum:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		forumMemberEngagedCount:0,
		forumPublishedCount:0,
		isSession:''
	},
	forumMessages:{
		data: null,
		links: null,
		meta: null,
		current_page:null,
		forumMemberEngagedCount:0,
		forumPublishedCount:0,
		isSession:''
	},
}
