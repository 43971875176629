export const getForumList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/forum'+ query).then((response) => {
        commit('setForum', response.data);
        return true;
    }).catch((error) => {
        return false;
    })
}
export const storeFormPostMessage = ({ commit, dispatch }, {payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/post-forum-message',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    })
}
export const likeDislikeFormPostMessage = ({ commit, dispatch }, {payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/forum-like-dislike-messages',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    })
}
export const store = ({ commit, dispatch }, {payload}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/store',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    })
}
export const getForumById = ({ commit, dispatch }, {payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-forum-by-id/'+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    })
}
export const getForumMessageList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/forum-messages'+ query).then((response) => {
        commit('setForumMessages', response.data);
        return true;
    }).catch((error) => {
        return false;
    })
}
export const setForumStatus = ({ commit, dispatch }, {payload,context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/set-forum-status',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        return false;
    })
}