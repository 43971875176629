<template>
    <div>  
        <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
        <div>   
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-full mx-4 md:mx-0 md:px-4 md:px-0">       
                    <div class="border-2 rounded-2xl shadow-lg rounded-2xl relative flex  bg-white outline-none focus:outline-none max-w-lg mx-auto border-2 border-BgFeedback">        
                        <div class="p-5 w-full ">
                            <div class="flex justify-between">
                                <div class="px-0 md:px-6">
                                    <label class="text-xl md:text-2xl font-bold uppercase tracking-wide">
                                        <!-- Feedback -->{{$t('feedback ')}}
                                    </label>
                                </div>
                                <div class="relative">
                                    <button class="absolute inset-x-2 -top-7" type="button" @click="showAddFeedback(false)">
                                       <svg class="bg-white rounded-full" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path fill="#DA5352" d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z"/></svg>
                                    </button>
                                </div>
                            </div>

                            <form>
                                <div class=" px-0 md:px-6 py-6 space-x-2 md:space-x-0 space-y-4">
                                 <div class="flex">
                                    <div class="w-5/12">
                                        <div>
                                            <label>
                                                <img v-if="isAuthenticated" :src="profile_image" class="w-28 h-28 border-2 rounded-md object-cover" >
                                                <img v-else src="/images/user.webp" class="w-28 h-28 border-2 rounded-md object-cover" >
                                            </label>
                                        </div>                                        
                                    </div>
                                    <div class="w-7/12 space-y-4">
                                        <div class="w-full flex flex-col space-y-1">
                                            <input type="text" class="px-4 py-1 text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full"
                                            :placeholder="$t('email')" 
                                            name="email" 
                                            v-model.trim="feedback.email" 
                                            ref="email" 
                                            @blur="clearValidation('email')" autocomplete="off" 
                                            :disabled="isAuthenticated == true">
                                            <span v-if="!errors.email" class="text-xs text-red-400" >The email field is required.</span>
                                            <span v-if="!errors.invalidEmail" class="text-xs text-red-400">
                                            <!-- Invalid Email -->{{$t('invalid-email')}}
                                            </span>
                                        </div>
                                        <div class="w-full flex flex-col space-y-1">
                                            <input type="text" class="px-4 py-1 text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" 
                                            :placeholder="$t('first-name')" 
                                            name="first-name" 
                                            v-model.trim="feedback.first_name" 
                                            ref="firstName" 
                                            @blur="clearValidation('first_name')" autocomplete="off" 
                                            :disabled="isAuthenticated == true">
                                            <span v-if="!errors.first_name" class="text-xs text-red-400" >The first name field is required.</span> 
                                        </div>
                                        <div class="w-full flex-col rounded-md space-y-3">
                                            <input type="text" class="px-4 py-1 border border-gray-300 text-sm text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" 
                                            :placeholder="$t('Last name')" 
                                            name="last-name" 
                                            v-model.trim="feedback.last_name" 
                                            ref="lastName" 
                                            @blur="clearValidation('last_name')" autocomplete="off" 
                                            :disabled="isAuthenticated == true">
                                            <span v-if="!errors.last_name" class="text-xs text-red-400" >The last name field is required.</span>      
                                        </div>                                        
                                    </div>
                                </div>

                                <div class="w-full flex flex-col space-y-1">
                                    <textarea rows="3" type="text" class="px-4 py-1  text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('message')" 
                                    name="message" 
                                    v-model.trim="feedback.message" 
                                    ref="message" 
                                    @blur="clearValidation('message')" autocomplete="off">
                                    </textarea>
                                    <span v-if="!errors.message" class="text-xs text-red-400" >The message field is required.</span> 
                                </div>

                                <div class="md:flex space-x-4">
                                    <div>
                                        <span class="text-blue-600 text-sm font-semibold">
                                        <!-- Rate Us -->{{$t('rate-us ')}}
                                        </span>
                                    </div>
                                    <div class="flex flex-col space-y-1">
                                        <label class="flex space-x-1">
                                            <svg class="cursor-pointer fill-current" v-on:change="star" v-for="(i,key) in stars" :value="key ++" :class="i.class" @click="handleStars(i.number)" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><rect fill="none" height="24" width="24"/><path d="M14.43,10l-1.47-4.84c-0.29-0.95-1.63-0.95-1.91,0L9.57,10H5.12c-0.97,0-1.37,1.25-0.58,1.81l3.64,2.6l-1.43,4.61 c-0.29,0.93,0.79,1.68,1.56,1.09L12,17.31l3.69,2.81c0.77,0.59,1.85-0.16,1.56-1.09l-1.43-4.61l3.64-2.6 c0.79-0.57,0.39-1.81-0.58-1.81H14.43z"/></g></svg>
                                        </label>
                                        <span v-if="!errors.star"  class="text-xs text-red-400" >The rate us field is required.</span>
                                    </div>
                                </div>                                        

                                <div class="flex justify-end">
                                    <button type="button" :disabled="isSubmit" @click="submitFeedback()" class="text-sm border border-blue-300 justify-center block flex md:w-28 w-20 md:py-2 py-1 rounded-md" :class="[isSubmit ? 'font-semibold cursor-not-allowed  bg-gray-200' : 'bg-BgFeedback hover:bg-blue-500 text-white font-semibold']">
                                        <svg v-if="isSubmit" class="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                                        <span v-if="!isSubmit">
                                        <!-- Submit -->{{$t('submit')}}
                                        </span>
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>                                 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

    export default  ({
        data: function() {
            return {
                isAuthenticated: false,
                stars: [
                    {number: '1', class:'text-gray-400'},
                    {number: '2', class:'text-gray-400'},
                    {number: '3', class:'text-gray-400'},
                    {number: '4', class:'text-gray-400'},
                    {number: '5', class:'text-gray-400'},
                ],
                isSubmit:false,
                reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,  
                feedback:{
                    email: '',
                    first_name: '',
                    last_name: '',
                    message: '',
                    star: '',
                },
                errors:{
                   email:true,
                   invalidEmail : true,
                   first_name:true,
                   last_name:true,
                   message:true,
                   star:true,
                },
            }
        },
        components: {

        },
        computed: {
            ...mapGetters({
                
             }),
        },
        methods: {     
            ...mapMutations({
                'showAddFeedback':'feedback/showAddFeedback',
            }),

            ...mapActions({
                addFeedback: 'feedback/addFeedback',
            }),

            async getProfileDetails(){
                let emailid = localStorage.getItem('email');
                const payload = {
                    email : emailid
                }
                try{
                    const res = await this.$store.dispatch("myProfile/getProfileDetails",{payload: payload});
                    this.feedback.email = res.email;
                    this.feedback.first_name = res.firstname;
                    this.feedback.last_name = res.lastname;
                    this.profile_image = res.profile_image;
                    
                } catch(error){
                    console.log("Error in response", error);
                }finally{
                }
            },

            validateForm() {
                let isFormValid = true;

                if (!this.feedback.email) {
                    this.$refs.email.focus();
                    this.errors.email = false;
                    isFormValid = false;
                }else if (!this.reg.test(this.feedback.email)) {
                    this.errors.invalidEmail = false;
                    isFormValid = false;
                }
                else if (!this.feedback.first_name) {
                    this.$refs.firstName.focus();
                    this.errors.first_name = false;
                    isFormValid = false;
                }
                else if (!this.feedback.last_name) {
                    this.$refs.lastName.focus();
                    this.errors.last_name = false;
                    isFormValid = false;
                }
                else if (!this.feedback.message) {
                    this.$refs.message.focus();
                    this.errors.message = false;
                    isFormValid = false;
                }
                else if (!this.feedback.star) {
                    this.errors.star = false;
                    isFormValid = false;
                }
                
                return isFormValid;
            },

            clearValidation(input){
                 if (input === 'email') {
                  this.errors.email = true;
                  this.errors.invalidEmail = true; // Add this line to reset the invalidEmail error
                } else {
                  this.errors[input] = true;
                }
            },

            handleStars(starNo){
                this.star = starNo
                this.feedback.star = starNo;
                for (let i = 0; i <= this.stars.length - 1; i++) {
                    this.stars[i].class = i < starNo ? 'text-yellow-500' : 'text-gray-400';
                }
            },

            submitFeedback() {
                try {
                    if (this.validateForm()) {
                        this.isSubmit = true;
                        this.addFeedback({
                            payload: this.feedback,
                            context: this,
                        })
                        .then((success) => {
                            if (success) {
                                this.showAddFeedback(false);
                            } else {
                                this.isSubmit = false;
                                this.showAddFeedback(true);
                            }
                        })
                        .catch((error) => {
                            this.isSubmit = false;
                        });
                    } else {
                        this.isSubmit = false;
                    }
                } catch (error) {
                    this.isSubmit = false;
                    console.error('An error occurred during the submission:', error);
                }
            },


        },
        mounted() {
            this.isAuthenticated = this.isUserLoggedIn();
            this.getProfileDetails();
        },



    });
</script>

<style>
 
</style>